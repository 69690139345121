var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-sidebar',{attrs:{"id":"sidebar-backdrop","backdrop":"","shadow":"","bg-variant":"white","sidebar-class":"sidebar-lg","no-header":"","right":"","visible":_vm.productEditSidebarActive},on:{"change":function (val) { return _vm.$emit('update:product-edit-sidebar-active', val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Editar producto ")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('validation-observer',{ref:"simpleRules"},[(_vm.show)?_c('b-form',{staticClass:"p-2",on:{"submit":_vm.onSubmit}},[_c('validation-provider',{attrs:{"name":"nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Producto:","label-for":"input-name"}},[_c('b-form-input',{attrs:{"id":"input-name","placeholder":"Ingresa el nombre","required":""},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"descripción"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Descripción:","label-for":"input-description"}},[_c('b-form-input',{attrs:{"id":"input-description","placeholder":"Ingresa una descripción"},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"unidad de medida","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Unidad de Medida:","label-for":"select-unit"}},[(_vm.units)?_c('v-select',{attrs:{"options":_vm.units,"reduce":function (unit) { return unit.id; },"clearable":true,"input-id":"select-unit","required":""},on:{"search":_vm.onSearchUnits},model:{value:(_vm.form.unit_id),callback:function ($$v) {_vm.$set(_vm.form, "unit_id", $$v)},expression:"form.unit_id"}},[_c('template',{slot:"no-options"},[_vm._v(" Lo siento, no se encontraron unidades de medida ")])],2):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"categoría","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Categoría:","label-for":"select-category"}},[(_vm.categories)?_c('v-select',{attrs:{"options":_vm.categories,"reduce":function (category) { return category.id; },"clearable":true,"input-id":"select-category","required":""},on:{"search":_vm.onSearchCategories},model:{value:(_vm.form.category_id),callback:function ($$v) {_vm.$set(_vm.form, "category_id", $$v)},expression:"form.category_id"}},[_c('template',{slot:"no-options"},[_vm._v(" Lo siento, no se encontraron categorías ")])],2):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"precio","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Precio de venta:","label-for":"input-price"}},[_c('b-form-input',{staticClass:"form-control",attrs:{"id":"input-price","placeholder":"Precio de venta en quetzales","type":"number","min":"1","step":"any"},model:{value:(_vm.form.price),callback:function ($$v) {_vm.$set(_vm.form, "price", $$v)},expression:"form.price"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"costo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Costo:","label-for":"input-cost"}},[_c('b-form-input',{staticClass:"form-control",attrs:{"id":"input-cost","placeholder":"Costo en quetzales","type":"number","min":"1","step":"any"},model:{value:(_vm.form.cost),callback:function ($$v) {_vm.$set(_vm.form, "cost", $$v)},expression:"form.cost"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"estado"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Estado:","label-for":"input-status"}},[_c('b-form-select',{attrs:{"id":"input-status","options":_vm.statusOptions,"required":""},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('b-form-group',{attrs:{"label":"Stock total:"}},[_c('b',{staticStyle:{"font-size":"25px"}},[_vm._v(_vm._s(_vm.form.stock))])]),_c('b-form-group',{attrs:{"label":"Stock por tienda:"}},_vm._l((_vm.form.stock_by_store),function(item,index){return _c('div',{key:index},[(item.store === _vm.userData().store)?_c('div',{staticClass:"font-weight-bold text-info"},[_c('span',[_vm._v(_vm._s(item.store)+": ")]),_c('b-badge',{attrs:{"variant":(item.stock_store > 0) ? 'light-success' : 'light-danger'}},[_vm._v(" "+_vm._s(item.stock_store)+" ")])],1):_c('div',[_c('span',[_vm._v(_vm._s(item.store)+": ")]),_c('b-badge',{attrs:{"variant":"light-secondary"}},[_vm._v(" "+_vm._s(item.stock_store)+" ")])],1)])}),0),_c('b-button',{attrs:{"type":"submit","variant":"primary"}},[_vm._v(" Actualizar ")])],1):_vm._e()],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }