<template>

  <div>
    <products-add
      :product-add-sidebar-active.sync="productAddSidebarActive"
      @refreshData="getProducts"
    />

    <products-edit
      v-if="updateShow"
      :product-id="productId"
      :product-edit-sidebar-active.sync="productEditSidebarActive"
      @refreshData="getProducts"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostrar</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
            <b-button
              _class="btn-icon rounded-circle"
              variant="primary"
              @click="productAddSidebarActive = true"
            >
              <!-- <feather-icon icon="PlusIcon" /> -->
              Nuevo
            </b-button>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="4"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Buscar..."
              />
            </div>
          </b-col>

          <!-- Categories -->
          <b-col
            cols="12"
            md="4"
          >
            <v-select
              v-if="categoryOptions"
              v-model="selectedCategory"
              :options="categoryOptions"
              :reduce="category => category.id"
              placeholder="Ver por categoría"
              @search="onSearchCategories"
            >
              <template slot="no-options">
                Lo siento, no se encontraron categorías
              </template>
            </v-select>
          </b-col>
        </b-row>

      </div>

      <div class="mx-2 mb-2">
        <b-row>

          <!-- Pagination info -->
          <b-col
            v-if="productsMeta"
            cols="12"
            sm="12"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Mostrando del {{ productsMeta.from }} al {{ productsMeta.to }} de {{ productsMeta.total }} registros</span>

            <download-excel
              v-if="productsData"
              class="btn"
              :data="productsData"
              worksheet="Productos"
              :name="'productos_' + getCurrentDate() + '.xls'"
            >
              <feather-icon
                icon="DownloadIcon"
              />
              Descargar Excel
            </download-excel>
          </b-col>
        </b-row>
      </div>

      <b-table
        class="position-relative"
        hover
        responsive
        :busy="tableIsBusy"
        primary-key="id"
        show-empty
        empty-text="No se encontraron registros coincidientes"
        :items="productsData"
        :fields="productsFields"
        :per-page="perPage"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle" />
          </div>
        </template>

        <template #head(id)="data">
          <span
            class="text-secondary pointer"
            @click="sortColumn(data.column)"
          >{{ data.label }}
          </span>
        </template>

        <template #head(name)="data">
          <span
            class="text-secondary pointer"
            @click="sortColumn(data.column)"
          >{{ data.label }}
          </span>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <!-- Dropdown -->
            <b-dropdown
              variant="link"
              no-caret
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item
                @click="updateProduct(data.item.id)"
              >
                <feather-icon icon="Edit2Icon" />
                <span class="align-middle ml-50">Editar</span>
              </b-dropdown-item>
              <b-dropdown-item
                @click="deleteProduct(data.item.id)"
              >
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Eliminar</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>

        <!-- Column: Id -->
        <template #cell(id)="data">
          <b-link
            class="font-weight-bold text-warning"
            @click="$emit('addPurchaseTab', data.item.id)"
          >
            #{{ data.item.id }}
          </b-link>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge :variant="`light-${ data.item.status === 'Y' ? 'success' : 'danger' }`">
            {{ data.item.status === 'Y' ? 'Activo' : 'Inactivo' }}
          </b-badge>
        </template>

        <template #cell(price)="data">
          Q.{{ data.item.price }}
        </template>

        <template #cell(cost)="data">
          Q.{{ data.item.cost }}
        </template>

        <!-- Disponible total -->
        <template #cell(stock)="data">
          <b-badge :variant="(data.item.stock > 0) ? 'light-success' : 'light-danger'">
            {{ data.item.stock }}
          </b-badge>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>

          <!-- Pagination info -->
          <b-col
            v-if="productsMeta"
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Mostrando del {{ productsMeta.from }} al {{ productsMeta.to }} de {{ productsMeta.total }} registros</span>
          </b-col>

          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-if="productsMeta"
              v-model="currentPage"
              :total-rows="productsMeta.total"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>

</template>

<script>
import axios from '@axios'
import vSelect from 'vue-select'
import downloadExcel from 'vue-json-excel'
import ProductsAdd from './ProductsAdd.vue'
import ProductsEdit from './ProductsEdit.vue'

export default {
  components: {
    vSelect,
    ProductsAdd,
    ProductsEdit,
    downloadExcel,
  },

  data() {
    return {
      productAddSidebarActive: false,
      productEditSidebarActive: false,

      searchQuery: '',

      sortField: 'id',
      sortDesc: 'desc',

      perPage: 10,
      perPageOptions: [5, 10, 25, 50, 100, 'Todos'],
      currentPage: 1,

      productsFields: [
        { key: 'actions', label: 'Acciones' },
        { key: 'id', label: '#' },
        { key: 'name', label: 'Nombre' },
        { key: 'description', label: 'Descripción' },
        { key: 'status', label: 'Estado' },
        { key: 'unit', label: 'Medida' },
        { key: 'category', label: 'Categoría' },
        { key: 'stock', label: 'Stock Total' },
        // { key: 'stock_by_store', label: 'Stock por tienda' },
        { key: 'price', label: 'Precio de venta' },
        { key: 'cost', label: 'Costo' },
        { key: 'created_at', label: 'Creado' },
        { key: 'updated_at', label: 'Actualizado' },
      ],
      productsData: null,
      productsMeta: null,

      updateShow: false,
      productId: 0,

      categoryOptions: [],
      selectedCategory: '',

      tableIsBusy: true,
    }
  },

  watch: {
    perPage() {
      this.tableIsBusy = true
      this.getProducts()
    },
    currentPage() {
      this.tableIsBusy = true
      this.getProducts()
    },
    searchQuery() {
      this.tableIsBusy = true
      this.getProducts()
    },
    selectedCategory() {
      this.tableIsBusy = true
      this.getProducts()
    },
  },

  created() {
    this.getProducts()
    this.getCategories()
  },

  methods: {
    userData() {
      return JSON.parse(localStorage.getItem('FerreAuthUser'))
    },

    getCategories() {
      axios
        .get('category?perPage=100&sortField=id&sortDesc=desc&filterField=status&filterValue=Y')
        .then(response => {
          response.data.data.forEach(element => {
            this.categoryOptions.push({
              label: element.name,
              id: element.id,
            })
          })
        })
        .catch(error => {
          this.showErrors(error)
        })
    },

    onSearchCategories(search, loading) {
      if (search.length) {
        loading(true)
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          axios
            .get(`category?filterField=status&filterValue=Y&query=${search}`)
            .then(response => {
              loading(false)
              this.categoryOptions = []
              response.data.data.forEach(element => {
                this.categoryOptions.push({
                  label: element.name,
                  id: element.id,
                })
              })
            })
            .catch(error => {
              this.showErrors(error)
            })
        }, 300)
      } else {
        this.getCategories()
      }
    },

    getProducts() {
      if (this.perPage === 'Todos') {
        this.perPage = this.productsMeta.total
      }

      const filterArray = [
        { field: 'category_id', value: this.selectedCategory || '' },
      ]

      axios
        .get(`product?filters=${JSON.stringify(filterArray)}&perPage=${this.perPage}&page=${this.currentPage}&query=${this.searchQuery}&sortField=${this.sortField}&sortDesc=${this.sortDesc}`)
        .then(response => {
          this.tableIsBusy = false
          this.productsMeta = response.data.meta
          this.productsData = response.data.data
        })
        .catch(error => {
          this.showErrors(error)
        })
    },

    updateProduct(id) {
      this.updateShow = true
      this.productEditSidebarActive = true
      this.productId = id
    },

    deleteProduct(id) {
      this.$bvModal
        .msgBoxConfirm('¿Deseas eliminar el producto?', {
          title: 'Eliminar producto',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          okTitleVariant: 'outline-danger',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            axios
              .delete(`product/${id}`)
              .then(response => {
                this.makeToast('success', 'Producto eliminado', `Se ha eliminado el producto ${response.data.data.name}.`)
                this.getProducts()
              })
              .catch(error => {
                this.showErrors(error)
              })
          }
        })
    },

    sortColumn(field) {
      if (this.sortField === field) {
        if (this.sortDesc === 'asc') {
          this.sortDesc = 'desc'
        } else {
          this.sortDesc = 'asc'
        }
      } else {
        this.sortField = field
        this.sortDesc = 'asc'
      }
      this.getProducts()
    },

    makeToast(variant = null, title = null, message = null) {
      this.$bvToast.toast(message, {
        title,
        variant,
        solid: true,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.pointer {
  cursor: pointer;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
